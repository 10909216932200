/* eslint-disable */
import Script from 'next/script';
import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import {en, es, pt} from 'make-plural/plurals';

// Load plural data
i18n.loadLocaleData({
  en: {plurals: en},
  es: {plurals: es},
  pt: {plurals: pt},
});

// cache loaded translations (works serverside only)
let transCache;
let localeCache;

// used during render
export function activate(locale) {
  let translations;
  if (transCache) {
    // server only
    translations = transCache;
  } else if (typeof window != 'undefined' && window.translations) {
    // client only
    translations = window.translations;
  } else {
    console.error('i18n: No translations found');
    return;
  }
  try {
    i18n.load(locale, translations);
  } catch (e) {
    console.error('i18n: Lingui load trouble', e);
  }
  // very noisy during build, but hand for debugging
  //console.log('Locale loaded, activating', locale);
  localeCache = locale;
  transCache = translations;
  i18n.activate(locale);
}

// used during getInitialProps
export async function asyncActivate(locale) {
  let translations;
  if (locale === 'en') {
    translations = {foobar:'foobar'}; // don't send english translations!
  } else {
    translations = await import(`../locale/${locale}/messages.json`);
    translations = {...translations}; // convert module to object
  }
  localeCache = locale;
  transCache = translations;
  return activate(locale);
}

export default function I18nWrapper({children}) {
  let script = null;
  if (!localeCache) {
    console.error('i18n: locale not available for I18nWrapper.');
  } else if (localeCache !== 'en') {
    script =  <Script src={`/locale/${localeCache}/messages.js`} strategy='beforeInteractive'/>
  } else {
    script =  <Script strategy='beforeInteractive'>{'window.translations = {foobar:"foobar"}'}</Script>
  }
  return (<>
    <I18nProvider i18n={i18n}>
      {script}
      {children}
    </I18nProvider>
    </>
  );
}

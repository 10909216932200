import {createContext, useMemo, useState, useContext} from 'react';
import PropTypes from 'prop-types';

export const ToastContext = createContext({});

export const useToastNotification = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastNotification must be called inside of ToastProvider');
  }
  return context;
};

export default function ToastProvider({children}) {
  const [notifications, setNotifications] = useState({});
  const stacks = {
    'top-left': useState([]),
    'top-right': useState([]),
    'bottom-left': useState([]),
    'bottom-right': useState([]),
  };
  const addToastNotification = notification => {
    window.closedTimerToastNotifications = window.closedTimerToastNotifications || {};
    if (notifications[notification.name]) return;
    const [stack, setStack] = stacks[notification.position];
    const updatedNotifications = {...notifications};
    updatedNotifications[notification.name] = notification;

    const updatedStack = [...stack, notification.name];
    if (updatedStack.length > 3) {
      const notificationToDelete = updatedStack.shift();
      delete updatedNotifications[notificationToDelete];
    }
    setStack(updatedStack);
    setNotifications(updatedNotifications);
  };

  const removeToastNotification = notification => {
    const [stack, setStack] = stacks[notification.position];
    const updatedStack = stack.filter(el => el !== notification.name);
    setStack(updatedStack);
    if (!notifications[notification.name]) return;
    const updatedNotifications = {...notifications};
    delete updatedNotifications[notification.name];
    setNotifications(updatedNotifications);
  };

  const contextValues = useMemo(
    () => ({
      notifications,
      stacks,
      setNotifications,
      addToastNotification,
      removeToastNotification,
    }),
    [notifications]
  );
  return <ToastContext.Provider value={contextValues}>{children}</ToastContext.Provider>;
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import dynamic from 'next/dynamic';
import {createElement} from 'react';

const DEFAULT_DURATION = 5000;

const Notification = dynamic(() => import('../../components/UI/Molecules/Notification'), {
  ssr: false,
});

const createNotificationBuilder =
  type =>
  ({title, message, key, duration}) => {
    const elm = createElement(
      Notification,
      {title, type, key: key || Date.now(), duration: duration || DEFAULT_DURATION},
      message
    );
    return elm;
  };

const success = createNotificationBuilder('success');
const danger = createNotificationBuilder('danger');
const warning = createNotificationBuilder('warning');
const info = createNotificationBuilder('info');

export default {
  success,
  danger,
  warning,
  info,
};

const REQUEST_TIMEOUT_MILLISECONDS = 10000; // 10 seconds
let userRequestedData = null;
let resolveUserRequestedDataPromise = null;
const userRequestedDataPromise = new Promise(resolve => {
  resolveUserRequestedDataPromise = resolve;
});

const setUserRequestedData = data => {
  userRequestedData = data;
  resolveUserRequestedDataPromise(data);
};

const getTimeoutPromise = new Promise(resolve => {
  setTimeout(() => resolve(null), REQUEST_TIMEOUT_MILLISECONDS);
});

const getUserRequestedData = async () => {
  if (userRequestedData !== null) {
    return userRequestedData;
  }
  return Promise.race([userRequestedDataPromise, getTimeoutPromise]);
};

export {setUserRequestedData, getUserRequestedData};

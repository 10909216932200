import {track as trackGTM, page as pageGTM} from './gtm/gtm-manager';
import {isMarketingCookieEnabled, isBlacklistedEvent} from '../utils/cookiesPolicy';

const trackEventQueue = [];

const analyticsProviders = [];
let analyticsReady = false;

async function loadAnalytics(router) {
  const loaders = [];
  // if we ever need to load multiple systems again...
  // loaders.push(loadSegment());
  loaders.push(loadAmplitude());
  return Promise.all(loaders).then(() => {
    console.log('all analytics providers loaded');
    analyticsReady = true;
    bindRouter(router);
    flushTrackEventQueue();
  });
}

/*
async function loadSegment() {
  const SegmentAnalytics = await import('./segment');
  analyticsProviders.push(SegmentAnalytics.default);
}
*/

async function loadAmplitude() {
  const AmplitudeAnalytics = await import('./amplitude');
  await AmplitudeAnalytics.default.getInitPromise();
  await AmplitudeAnalytics.default.identify();
  analyticsProviders.push(AmplitudeAnalytics.default);
}

async function bindRouter(router) {
  const handleRouteChange = url => {
    analyticsProviders.forEach(provider => provider.page(url));
    pageGTM();
  };

  // track first page load:
  handleRouteChange(window.location.href);
  // failsafe to prevent double tracking
  router.events.off('routeChangeComplete', handleRouteChange);
  // on mount
  router.events.on('routeChangeComplete', handleRouteChange);
  return () => {
    // on unmount
    router.events.off('routeChangeComplete', handleRouteChange);
  };
}

function trackEvent(label, properties = {}) {
  if (!label) {
    console.error('ERROR: trackEvent() requires a label argument.');
    return;
  }
  if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
    console.log('trackEvent (not shown in prod):', label, properties);
  }
  // check for blacklisted events
  if (isMarketingCookieEnabled() === false && isBlacklistedEvent(label)) {
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
      console.log('blacklisted Event (not shown in prod):', label, properties);
    }
    return;
  }

  if (analyticsReady) {
    analyticsProviders.forEach(provider => provider.track(label, properties));
    trackGTM(label, properties);
  } else {
    trackEventQueue.push({label, properties});
  }
}

function flushTrackEventQueue() {
  trackEventQueue.forEach(({label, properties}) => {
    trackEvent(label, properties);
  });
}

export {trackEvent, loadAnalytics, analyticsProviders};

/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */
/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */
/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */
/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */
/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */
/* DO NOT USE - DOES NOT WORK ON STATIC PAGES. See variant_provider.jsx and it's test for usage */

/* eslint-disable camelcase */
import {createContext, useState, useMemo, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useCookies} from './Cookies/CookiesContext';

export const ExperimentContext = createContext({
  experiments: {},
});

export default function ExperimentProvider({children}) {
  const experimentCookie = useCookies('experiment_id');
  const [experiments, setExperiments] = useState(parseToObj(experimentCookie));

  useEffect(() => {
    setExperiments(parseToObj(experimentCookie));
  }, [experimentCookie]);

  const value = useMemo(() => ({experiments}), [experiments]);

  return <ExperimentContext.Provider value={value}>{children}</ExperimentContext.Provider>;
}

ExperimentProvider.defaultProps = {
  children: null,
  cookies: {},
};
ExperimentProvider.propTypes = {
  children: PropTypes.node,
  cookies: PropTypes.shape({experiment_id: PropTypes.string}),
};

/* HOOKS */
export function useExperiment(experimentIdWanted) {
  const {experiments} = useContext(ExperimentContext);
  return experiments[experimentIdWanted];
}

/* HELPERS */
function parseToObj(experimentId) {
  if (!experimentId) return {};

  const experiments = experimentId.split('!').reduce((accumulator, currentValue) => {
    const [experiment, variable] = currentValue.split(':');
    accumulator[experiment] = variable;
    return accumulator;
  }, {});

  return experiments;
}

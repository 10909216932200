import {getCookie} from './cookies';

const REQUEST_DATA = {
  headers: {'Content-Type': 'application/json'},
  referrerPolicy: 'strict-origin-when-cross-origin',
  mode: 'cors',
  credentials: 'include',
  accept: 'application/json, text/javascript, */*; q=0.01',
};
const UNSUBSCRIBE_MAILING_LIST_PREFERENCES = {
  placeit_promotion_notifications: false,
  download_email_notifications: false,
  product_update_notifications: false,
  feedback_request_notifications: false,
};
const UNSUBSCRIBE_RAILS_ENDPOINT = '/email_lists/update_notifications';
const UNSUBSCRIBE_CONTROL_COOKIE_NAME = 'unsubscribed_from_mailing_list';

const createUnsubscribeControlCookie = function createUnsubscribeControlCookie() {
  document.cookie = `${UNSUBSCRIBE_CONTROL_COOKIE_NAME}=true; Path=/`;
};

const unsubscribeMailingListsUser = async function unsubscribeMailingListsUser() {
  try {
    const result = await fetch(UNSUBSCRIBE_RAILS_ENDPOINT, {
      ...REQUEST_DATA,
      body: JSON.stringify({
        user: UNSUBSCRIBE_MAILING_LIST_PREFERENCES,
      }),
      method: 'POST',
    }).then(response => response.json());
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const isUserUnsubscribedMailingLists = function isUserUnsubscribedMailingLists() {
  return Boolean(getCookie(UNSUBSCRIBE_CONTROL_COOKIE_NAME)) === true;
};

export {
  unsubscribeMailingListsUser,
  createUnsubscribeControlCookie,
  isUserUnsubscribedMailingLists,
};

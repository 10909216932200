export default function legacyTrackingShim() {
  window.page = {
    userLocation: {
      getIpAddressData: () =>
        new Promise(resolve => {
          resolve({
            city: 'Portland',
            country: 'United States',
            countryCode: 'US',
          });
        }),
    },
  };
  window.ExperimentsManager = {
    getInstance: () => ({
      runExperiment: () => {},
    }),
  };
  window.$ = () => ({
    on: () => ({}),
    find: () => ({
      on: () => {},
    }),
  });
  window.Helpers = {
    App: {
      getUser: () => ({}),
    },
  };
  window.pageType = {
    type: {},
  };
  window.analytics = {
    identify() {},
  };
  window.Class = {};
  window.e = {
    push() {},
    filter() {},
  };
  window.navbar = {
    removeClass() {},
  };
}

// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _102920241500110120240500Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_102920241500110120240500() {
  return React.useContext(_102920241500110120240500Context);
}

export default _102920241500110120240500Context;
/* prettier-ignore-end */

/* eslint-disable camelcase */
import {createContext, useState, useMemo, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {getCookie, setSessionCookie} from '../../lib/utils/cookies';
import InitialValues from './InitialValue';
import {isBlacklistedCookie} from '../../lib/utils/cookiesPolicy';

export const CookiesContext = createContext(InitialValues);

export default function CookiesProvider({children, reqCookies}) {
  // Set cookies on server to make them accessible from any component.
  const [cookies, setCookiesToContext] = useState(() => {
    if (reqCookies)
      return {
        ...InitialValues,
        ...reqCookies,
        cookie_preferences: reqCookies.cookie_preferences
          ? JSON.parse(reqCookies.cookie_preferences)
          : undefined,
        pl_coupon: reqCookies.pl_coupon ? JSON.parse(reqCookies.pl_coupon) : '',
      };
    return InitialValues;
  });

  // Update cookies in the browser.
  const updateCookies = (name, value) => {
    if (isBlacklistedCookie(name) === true) {
      return;
    }
    setCookiesToContext(state => ({...state, name: value}));
    setSessionCookie(name, value);
  };

  // if server and browser cookies are different, the reset the cookies on the client
  useEffect(() => {
    process.env.NODE_ENV !== 'test' &&
      areCookiesDifferent(reqCookies, getCookie()) &&
      setCookiesToContext(getCookie());
  }, []);

  const value = useMemo(() => ({cookies, updateCookies}), [cookies]);

  return <CookiesContext.Provider value={value}>{children}</CookiesContext.Provider>;
}

CookiesProvider.defaultProps = {
  children: null,
  reqCookies: {},
};
CookiesProvider.propTypes = {
  children: PropTypes.node,
  reqCookies: PropTypes.shape({
    experiment_id: PropTypes.string,
    cookie_preferences: PropTypes.shape({
      preferences: PropTypes.bool,
      statistics: PropTypes.bool,
      marketing: PropTypes.bool,
    }),
    pl_coupon: PropTypes.node,
  }),
};

/* HOOKS */
export const useCookies = wantedCookie => {
  const {cookies} = useContext(CookiesContext);

  if (wantedCookie) {
    return cookies ? cookies[wantedCookie] : InitialValues[wantedCookie];
  }

  return cookies;
};

/* HELPERS */
function areCookiesDifferent(a, b) {
  return JSON.stringify(a) !== JSON.stringify(b);
}

import {getCookie} from './cookies';

const GOOGLE_CLIENT_ID_PREFIX_LIST = ['GA1.1.', 'GA1.2.'];
const GOOGLE_SESSION_ID_PREFIX_LIST = ['GS1.1.', 'GS1.2.'];
const GOOGLE_CLIENT_ID_COOKIE_NAME = '_ga';
const GOOGLE_SESSION_ID_COOKIE_NAME = {
  staging: '_ga_BLQQ27W7N6',
  production: '_ga_K4BRQBP74C',
};
const MAIN_BRANCH_NAME = 'main';

const getGoogleSessionIdCookieName = () => {
  let cookieName = GOOGLE_SESSION_ID_COOKIE_NAME.production;

  if (process.env.GIT_BRANCH !== MAIN_BRANCH_NAME) {
    cookieName = GOOGLE_SESSION_ID_COOKIE_NAME.staging;
  }

  return cookieName;
};

const getGoogleSessionId = function getGoogleSessionId() {
  const googleSessionIdCookieName = getGoogleSessionIdCookieName();

  return getGoogleIdData({
    cookieName: googleSessionIdCookieName,
    prefixList: GOOGLE_SESSION_ID_PREFIX_LIST,
    splitResult: true,
  });
};

// This method was copied from Rails' codebase
const getGoogleClientId = function getGoogleClientId() {
  return getGoogleIdData({
    cookieName: GOOGLE_CLIENT_ID_COOKIE_NAME,
    prefixList: GOOGLE_CLIENT_ID_PREFIX_LIST,
    splitResult: false,
  });
};

const getGoogleIdData = async function getGoogleIdData(config) {
  const cookie = await getCookie(config.cookieName);

  if (Boolean(cookie) === false) {
    return null;
  }

  return config.prefixList.reduce((accum, prefix) => {
    let result = accum.replace(prefix, '');

    if (config.splitResult) {
      [result] = result.split('.');
    }

    return result;
  }, cookie);
};

export {getGoogleSessionId, getGoogleClientId};

export function getCountryCode(cookies, headers) {
  const isCandidate = ['main', 'staging', 'feature/staging-override'].includes(
    process.env.GIT_BRANCH
  );
  if (!isCandidate) return cookies?.country_code || 'US';
  return headers?.['cf-ipcountry'] || '';
}

export default function CountryHelper() {
  return null;
}

CountryHelper.prerender = (contextName, value, country) => {
  if (!/^country/.test(contextName)) return value;
  const countryCode = contextName.replace(/^country/, '').toUpperCase();
  if (countryCode === country) return 'override';
  return value;
};

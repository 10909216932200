export const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const formatBRL = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

/** prices = {
  price: '',
  currency: 'USD' || 'BRL',
  annual_monthly_price: '',
  annual_monthly_price_in_cents: 0,
  annual_price: '',
  annual_price_in_cents: 0,
  monthly_price: '',
  monthly_price_in_cents: 0,
  coupon_valid: false,
  coupon_scope: '',
  discounted_prices: {
    monthly_price_in_cents: 0,
    annual_price_in_cents: 0,
    annual_monthly_price_in_cents: 0,
  },
}; */
export default function formatPrices(prices) {
  const formatter = prices.currency === 'USD' ? formatUSD : formatBRL;

  Object.keys(prices).forEach(key => {
    if (/_in_cents/.test(key))
      prices[key.replace('_in_cents', '')] = formatter.format(prices[key] / 100);
  });

  prices.discounted_prices &&
    Object.keys(prices.discounted_prices).forEach(key => {
      if (/_in_cents/.test(key))
        prices.discounted_prices[key.replace('_in_cents', '')] = formatter.format(
          prices.discounted_prices[key] / 100
        );
    });
  return prices;
}

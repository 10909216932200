import PropTypes from 'prop-types';
import {useState, createContext, useContext, useMemo, useEffect} from 'react';
import {useRouter} from 'next/router';

export const CampaignContext = createContext({
  getBadge: () => null,
  isActiveCampaign: () => null,
});

export function CampaignProvider({children}) {
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [headerstripHeight, setHeaderstripHeight] = useState(0);
  const router = useRouter();

  const getCampaigns = async () => {
    let url = `${window.location.origin}/api/v2/get_active_campaigns`;

    if (router.query?.active_campaigns) {
      url += `?active_campaigns=${router.query.active_campaigns}`;
    }

    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      const result = await response.json();
      setActiveCampaigns(result.campaigns);
    } catch (err) {
      console.error(err);
    }
  };

  const getBadge = path => {
    let badgeCampaign = null;
    activeCampaigns.forEach(campaign => {
      const result = campaign.badges.find(badge => badge.menu_link === path);
      if (result) {
        badgeCampaign = result;
      }
    });
    return badgeCampaign;
  };

  const isActiveCampaign = campaign => {
    return activeCampaigns.some(element => {
      return element.code_name === campaign;
    });
  };

  const getCampaignByCodeName = codeName =>
    activeCampaigns.find(campaign => campaign.code_name === codeName);

  useEffect(async () => {
    router.isReady && getCampaigns();
  }, [router.isReady]);

  useEffect(() => {
    if (document?.body) {
      document.body.style.paddingTop = `${headerstripHeight}px`;
      document.body.style.setProperty('--headerstrip-campaign-height', `${headerstripHeight}px`);
    }
  }, [headerstripHeight]);

  const value = useMemo(
    () => ({
      activeCampaigns,
      setActiveCampaigns,
      getBadge,
      isActiveCampaign,
      headerstripHeight,
      setHeaderstripHeight,
      getCampaignByCodeName,
    }),
    [activeCampaigns, setActiveCampaigns, headerstripHeight]
  );

  return <CampaignContext.Provider value={value}>{children}</CampaignContext.Provider>;
}

CampaignProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useCampaignContext() {
  const context = useContext(CampaignContext);

  if (context === undefined) {
    throw new Error('useCampaignContext must be used within a CampaignProvider');
  }

  return context;
}

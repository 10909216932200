import {useMemo} from 'react';

const BuildComponetTree = ({children, providers}) => {
  const lastIndex = providers.length - 1;
  let activeChildren = children;
  for (let i = lastIndex; i >= 0; i--) {
    const [Provider, props] = providers[i];
    activeChildren = <Provider {...(props || {})}>{activeChildren}</Provider>;
  }
  return useMemo(() => activeChildren, children);
};

export default BuildComponetTree;

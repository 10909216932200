import React, {useContext, useRef, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import styles from './SsoPopup.module.css';
import {UserContext} from '../../context/UserContext';
import {useCookies} from '../../context/Cookies/CookiesContext';
import {isMarketingCookieEnabled} from '../../lib/utils/cookiesPolicy';
import {
  unsubscribeMailingListsUser,
  createUnsubscribeControlCookie,
  isUserUnsubscribedMailingLists,
} from '../../lib/utils/unsubscribeMailingListsUser';

const URL = '/modal_sso/';

// TODO: We must remove /homepage.draft when it is no longer necessary.
const isHomepage = pathname => pathname === '/' || pathname === '/homepage.draft';

/**
 * Validates if should redirect to the For You page based on the pathname.
 * @param {Window} window - Window object to validate
 */
const shouldRedirectToForYou = ({location: {pathname}}) => isHomepage(pathname);

/**
 * Serach by URL parameters and gets the redirect_to parameter.
 * @returns {string} - redirect_to URL parameter
 */
const getRedirectToURLParameter = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('redirect_to');
};

/**
 * Based on URL parameters, it checks if it should display the SSO Modal
 * by checking if the user is in the homepage and if the URL contains
 * the redirect_to URL param.
 */
const shouldDisplaySsoModal = () => {
  return isHomepage(window.location.pathname) && !!getRedirectToURLParameter();
};

export default function SsoPopup() {
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const animateRef = useRef();
  const {loadUser, setSsoModalOpener, setIsSsoOpen} = useContext(UserContext);
  const loggedSessionCookie = useCookies('logged_session');
  const router = useRouter();
  let SsoWin;

  const modalCloser = () => {
    window?.removeEventListener('focus', modalCloser);
    window?.removeEventListener('message', loginSuccessCheck);
    setOpacity(0);
    SsoWin.close();
    setTimeout(() => {
      setShow(false);
      setIsSsoOpen(false);
    }, 600);
  };

  const loginSuccessCheck = e => {
    if (e?.data?.status === 'success') {
      loadUser();
      unsubscribeUserMailingLists();
      modalCloser();
      if (shouldDisplaySsoModal()) {
        const redirectTo = getRedirectToURLParameter();
        router.push(redirectTo);
        return;
      }
      if (shouldRedirectToForYou(window)) {
        router.push('/for-you');
      }
    }
  };

  const unsubscribeUserMailingLists = () => {
    const canUnsubscribe = !isMarketingCookieEnabled() && !isUserUnsubscribedMailingLists();

    if (canUnsubscribe === false) {
      return;
    }

    createUnsubscribeControlCookie();
    unsubscribeMailingListsUser();
  };

  const modalOpener = signInTab => {
    // close sso window on refocus
    window?.addEventListener('focus', modalCloser);

    // post message will let us know about successful login
    window?.addEventListener('message', loginSuccessCheck);

    // dim page contents with nice animation
    setShow(true);
    setIsSsoOpen(true);
    setTimeout(() => {
      // animation fix/hack that changes opacity after display
      setOpacity(1);
    }, 1);
    animateRef && animateRef.current && animateRef.current.beginElement();

    let tabArg = '?auto-login=true';
    if (signInTab) {
      tabArg = '?tab=signup';
    }

    // 590x675 seems like a good size
    const w = window.outerWidth < 590 ? window.outerWidth : 590;
    const h = window.outerHeight < 675 ? window.outerHeight : 675;
    const l = (window.outerWidth - w) / 2 + window.screenLeft;
    const t = (window.outerHeight - h) / 2 + window.screenTop - 20;
    SsoWin = window.open(
      URL + tabArg,
      'SsoModal',
      'resizable=no,toolbar=no,menubar=no,location=no,status=no,popup=yes' +
        `,width=${w},height=${h},top=${t},left=${l}`
    );

    SsoWin?.addEventListener('load', SsoLoad);
  };

  const SsoLoad = () => {
    SsoWin.apiPage = 'false';
  };

  useEffect(() => {
    // register modal opener method in UserContext
    // passing the function ref directly causes execution!
    setSsoModalOpener(() => modalOpener);
    // this makes it easier for marketing team to add links to login
    window.SsoModal = SsoPopup;
    window.addEventListener('load', () => {
      if (shouldDisplaySsoModal() && !loggedSessionCookie) {
        window.SsoModal.modalOpener();
      }
    });
  }, []);
  // this makes testing a little easier
  SsoPopup.modalOpener = modalOpener;

  return (
    show && (
      <div className={styles['sso-modal-container']}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={styles['sso-modal-circle']}
          style={{
            opacity: opacity ? '1' : '0',
            transitionDuration: '0.6s',
          }}
        >
          <circle cx="50%" cy="50%" r="10" fill="#000000DD">
            <animate
              fill="freeze"
              ref={animateRef}
              attributeName="r"
              from="20"
              to="2300"
              end="2300"
              dur="0.6s"
              repeatCount="0"
            />
          </circle>
        </svg>
        <img
          alt="Sign-up and Login screen loading"
          src="/assets/spinnerBlack.gif"
          className={styles['sso-modal-spinner']}
        />
        <div
          style={{
            opacity: opacity ? '1' : '0',
            transition: 'opacity',
            transitionDuration: '0.2s',
          }}
        >
          <button
            type="button"
            data-testid="SsoModalClose"
            id="SsoModalClose"
            className={styles['sso-modal-closer']}
            aria-label="Close"
          >
            <span id="SsoModalCloseX " aria-hidden="true">
              ×
            </span>
          </button>
        </div>
      </div>
    )
  );
}

import {useEffect, useState, useCallback} from 'react';

export default function useNavbarHeight() {
  const [height, setHeight] = useState(0);

  const updateHeight = useCallback(() => {
    const navbarHTML = document.getElementById('navbar');
    if (navbarHTML) {
      setHeight(navbarHTML.offsetHeight);
    }
  }, []);

  const onTransitionEnd = useCallback(() => {
    updateHeight();
  }, [updateHeight]);

  useEffect(() => {
    const navbarHTML = document.getElementById('navbar');
    if (navbarHTML) {
      navbarHTML.addEventListener('transitionend', onTransitionEnd);

      const observer = new MutationObserver(() => {
        updateHeight();
      });

      observer.observe(navbarHTML, {attributes: true, childList: true, subtree: true});

      updateHeight();

      return () => {
        observer.disconnect();
        window.removeEventListener('resize', updateHeight);
        navbarHTML.removeEventListener('transitionend', onTransitionEnd);
      };
    }
  }, [updateHeight, onTransitionEnd]);

  return height;
}

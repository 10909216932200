import React, {memo} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {withRouter} from 'next/router';
import {getCookie} from '../lib/utils/cookies';
import VariantContextPlasmic, {VariantContext as vc, ContextNames} from './VariantContextPlasmic';

let DynamicVarSelModal;
if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
  DynamicVarSelModal = dynamic(() => import('../components/Modal/VariantSelectionModal'), {
    ssr: false,
  });

export const VariantContext = vc;

/*
VariantContext These contexts deal generally with variants, which could be:
 1. the variants we show for AB test experiments
 2. global variants such as subscriber level
 3. scheduled variants
 4. other stuff in the future

 AB test variants are set directly via cookies or props.
 Other variant types may use helpers to set their values.
*/

// used to give provider children a consistant child reference
const StableChild = memo(
  class extends React.Component {
    render() {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('StableChild Render', Date.now());
      return this.props.children;
    }
  }
);

class VariantProvider extends React.Component {
  constructor(props) {
    super(props);
    /* variations can come from a few places:
      props - passed in by tests
      query - set by middleware from cookie. used by server rendered pages
      cookie - for staticly built pages, we read cookie on clientside
      state/context - setup by helpers, used to show variants such as subscriber level
    */
    const cookieVariations = this.parseExperimentId(getCookie('experiment_id'));
    const queryVariations = this.parseExperimentId(props?.query?.experiment_id);
    const propVariations =
      props.variations &&
      Object.entries(props.variations).reduce((accumulator, currentValue) => {
        accumulator[currentValue[0].toLowerCase()] = currentValue[1];
        return accumulator;
      }, {});
    const variations = {...propVariations, ...queryVariations, ...cookieVariations};
    this.state = {
      setVariations: function (variations = {}) {
        if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
          console.log('setVariations', variations);
        this.setState(variations);
      }.bind(this),
    };
    for (const contextName of ContextNames || []) {
      /// helpers can advise on variant value before render
      let initialValue = variations[contextName] || null;
      this.props.helpers?.forEach(helper => {
        if (!helper.props.prerender) return;
        initialValue =
          helper.props.prerender(contextName, initialValue, helper?.props?.data) || initialValue;
      });
      this.state[contextName] = initialValue;
    }
  }

  parseExperimentId(id = '') {
    const variations = {};
    id.split('!').forEach(expVar => {
      const [experiment, variant] = expVar.split(':');
      if (!experiment || !variant) return;
      variations[String(experiment).toLowerCase()] = variant;
    });
    return variations;
  }

  render() {
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
      console.log('VariantContext Render', Date.now());
    return (
      <VariantContextPlasmic {...this.state}>
        <StableChild>{this.props.children}</StableChild>
        {this.props.helpers}
        {DynamicVarSelModal && <DynamicVarSelModal {...this.state} />}
      </VariantContextPlasmic>
    );
  }
}

VariantProvider.propTypes = {
  children: PropTypes.node.isRequired,
  variations: PropTypes.object,
  globalVariants: PropTypes.object,
  helpers: PropTypes.array,
};

VariantProvider.defaultProps = {
  variations: undefined,
  globalVariants: undefined,
  helpers: undefined,
};

export const VariantProviderWithoutRouter = VariantProvider;
export default withRouter(VariantProvider);

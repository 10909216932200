// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _112720241500120220240559Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_112720241500120220240559() {
  return React.useContext(_112720241500120220240559Context);
}

export default _112720241500120220240559Context;
/* prettier-ignore-end */

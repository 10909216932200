import aa from 'search-insights';
import {getCookie} from '../utils/cookies';

const algoliaIndex = {
  production: {
    niceTemplates: 'nice_template_assets_relation_replica_index',
    index: 'Stage_production',
  },
  staging: {
    niceTemplates: 'staging_nice_template_assets_relation_replica_index',
    index: 'Stage_staging_1',
  },
};

class AlgoliaInsights {
  constructor() {
    this.userId = null;
    if (getCookie('algolia_staging') && process.env.GIT_BRANCH !== 'main') {
      aa('init', {
        appId: process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_STAGING,
        apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PW_STAGING,
      });
      this.environment = 'staging';
      return;
    }

    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_CLIENT,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PW,
    });
    this.environment = 'production';
  }

  set userToken(userId) {
    aa('setUserToken', userId);
    this.userId = userId;
  }

  get userToken() {
    return this.userId;
  }

  sendClickEvent = config => {
    let insightsMethod = 'clickedObjectIDs';
    let data = {
      index: config.index || algoliaIndex[this.environment].niceTemplates,
      eventName: config.eventName || 'click_event',
      objectIDs: [config.objectID.toString()],
    };

    if (config.queryID) {
      data = {
        ...data,
        queryID: config.queryID,
        positions: [config.position],
      };
      insightsMethod = 'clickedObjectIDsAfterSearch';
    }

    if (this.userToken) {
      data.userToken = this.userToken.toString();
    }

    aa(insightsMethod, data);
  };

  sendStageClickEvent = (objectID, position, queryID = null) => {
    let eventName = 'stageClick';

    if (queryID) {
      eventName = 'stageSearchClick';
    }

    this.sendClickEvent({
      index: algoliaIndex[this.environment].index,
      eventName,
      objectID,
      position,
      queryID,
    });
  };
}

const algoliaInsights = new AlgoliaInsights();
export default algoliaInsights;

// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _120220240601120420240500Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_120220240601120420240500() {
  return React.useContext(_120220240601120420240500Context);
}

export default _120220240601120420240500Context;
/* prettier-ignore-end */

import {
  getAllFavoritesRequest,
  createFavoriteRequest,
  deleteFavoriteRequest,
} from './services/favorites_request';

/**
 * Creates a new store for the Favorites List.
 * @param {Array} initialState  - The initialState of the Favorites List
 *
 * @returns {{
 *   get: () => Array;
 *   set: (newState: Array) => void;
 *   subscribe: (listener: Function) => void;
 * }} Favorites List store
 */
function createFavoriteStore(initialState) {
  let currentState = initialState;
  const listeners = new Set();
  return {
    get: () => currentState,
    set: newState => {
      currentState = newState;
      listeners.forEach(listener => listener(currentState));
    },
    subscribe: listener => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
}

const favoritesList = createFavoriteStore([]);

/**
 * Gets all favorites of an user and saves the result in the Favorites List.
 * @param {Number} userId  - The ID of the user that will be used
 *
 * @returns {void}
 */
const getAllFavorites = async userId => {
  try {
    await getAllFavoritesRequest(userId).then(favorites => {
      favoritesList.set(favorites);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Resets the Favorites List.
 * @returns {void}
 */
const cleanFavorites = () => {
  favoritesList.set([]);
};

/**
 * Marks a stage as favorite and updates the Favorites List adding the stage id
 * if the request is completed successfully.
 * @param {Number} stageId  - The ID of the stage that will be marked
 *
 * @returns {void}
 */
const createFavorite = async stageId => {
  try {
    await createFavoriteRequest(stageId).then(response => {
      if (response.stage === undefined) {
        throw new Error('The stage could not be saved.');
      }
      const currentFavorites = favoritesList.get();
      favoritesList.set([...currentFavorites, stageId]);
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Unmarks a stage as favorite and updates the Favorites List deleting the stage id
 * if the request is completed successfully.
 * @param {Number} stageId  - The ID of the stage that will be unmarked
 *
 * @returns {void}
 */
const deleteFavorite = async stageId => {
  try {
    await deleteFavoriteRequest(stageId).then(response => {
      if (response.stage === undefined) {
        throw new Error('The stage could not be deleted.');
      }
      const currentFavorites = favoritesList.get();
      favoritesList.set(currentFavorites.filter(id => stageId !== id));
    });
  } catch (error) {
    throw new Error(error);
  }
};

export {getAllFavorites, createFavorite, deleteFavorite, cleanFavorites};
export default favoritesList;

// plasmic doesnt export schedules so we must overload the variant name
// most things are stripped from variant name, we just get a string of digits
// we must build UTC Date objects from these digits

export const scheduleSets = [
  [
    // full dates with times: 11-28-2022 00:00 12-02-2022 11:59
    /^_(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3$4 $5:$6 Z|$7-$8-$9$10 $11:$12 Z',
  ],
  [
    // full dates with start time: 11-28-2022 00:00 12-02-2022
    /^_(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3$4 $5:$6 Z|$7-$8-$9$10 00:00 Z',
  ],
  [
    // dates: 11-28-2022 12-02-2022
    /^_(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3$4 00:00 Z|$5-$6-$7$8 00:00 Z',
  ],
  [
    // start date with time: 11-28-2022 10:00
    /^_(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3$4 $5:$6 Z|',
  ],
  [
    // start date: 11-28-2022
    /^_(\d\d)(\d\d)(\d\d)(\d\d)$/,
    '$1-$2-$3$4 00:00 Z|',
  ],
  [
    // hour range: 15-18, with the help of gimmeADate below
    /^_(\d\d)(\d\d)$/,
    '$1|$2',
  ],
];

export const formatSchedule = schedule => {
  let result = schedule;
  scheduleSets.forEach(set => {
    if (set[0].test(schedule)) {
      const [start, end] = schedule.replace(set[0], set[1]).split('|');
      result = `${start} - ${end}`;
    }
  });
  return result;
};

const gimmeADate = part => {
  if (part?.length === 2) {
    return new Date().setUTCHours(part);
  }
  return new Date(part);
};

// helpers must define a react compoment,
// this allows some helpers to use hooks
function ScheduledVariantHelper() {
  /*
    TODO: set a timeout to change value at endtime
    ...setVariation(value);
  */
  return null;
}

// VariantContent runs this to populate value before render
ScheduledVariantHelper.prerender = (contextName, value) => {
  if (!/^_\d\d/.test(contextName)) return value; // quicker short-circuit
  const now = new Date();
  scheduleSets.every(set => {
    if (!set[0].test(contextName)) return true; // every() only continues on true
    // variant contextName (aka schedule) matches this set
    value = null;
    const [start, end] = contextName.replace(set[0], set[1]).split('|');
    if (start && now < gimmeADate(start)) return false;
    if (end && now > gimmeADate(end)) return false;
    // we are in the window for the override variant
    console.log('scheduled content override', start, end, now, contextName);
    value = 'override';
    return false;
  });
  return value;
};

export default ScheduledVariantHelper;

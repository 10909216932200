import {useEffect, createContext, useContext, memo} from 'react';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {isInStudio} from '../plasmic-utils';
import legacyTrackingShim from './legacy-tracking-shim';
import {trackEvent, loadAnalytics} from './tracker';
import GtmManager from './gtm/gtm-manager';

const GtmMemo = memo(function gtmManager() {
  return <GtmManager />;
});

const AnalyticsContext = createContext(undefined);

function AnalyticsProvider({children}) {
  // track route changes
  const router = useRouter();

  useEffect(() => {
    if (router.pathname !== '/navbar') {
      legacyTrackingShim();
    }
    window.trackEvent = trackEvent;
  }, [router.events]);

  useEffect(() => {
    loadAnalytics(router);
  }, []);

  return (
    <AnalyticsContext.Provider value={trackEvent}>
      {children}
      {isInStudio(router.pathname) || <GtmMemo />}
    </AnalyticsContext.Provider>
  );
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Create an analytics hook that we can use with other components.
function useAnalytics() {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
}

export {useAnalytics, AnalyticsProvider};

import dynamic from 'next/dynamic';
import * as Portal from '@radix-ui/react-portal';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useToastNotification} from './Context';

const DynamicToastNotification = dynamic(() => import('.'), {ssr: false});

const TOAST_POSITION = {
  'top-left': 'top-0 left-0',
  'top-right': 'top-0 right-0',
  'bottom-left': 'bottom-0 left-0',
  'bottom-right': 'bottom-0 right-0',
};

function ToastNotification({isMobile, name}) {
  const {notifications, removeToastNotification} = useToastNotification();

  return (
    <DynamicToastNotification
      isMobile={isMobile}
      {...notifications[name]}
      name={name}
      onAction={() => {
        notifications[name].onAction();
        removeToastNotification(notifications[name]);
      }}
      onClose={() => {
        notifications[name].onClose();
        removeToastNotification(notifications[name]);
      }}
    />
  );
}
ToastNotification.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default function Manager() {
  const {notifications, stacks} = useToastNotification();

  // ? We are forcing the portal to append it into document.body because otherwise it's being added
  // ? using the Notification portal, this might be a radix bug.
  const [portalElement, setPortalElement] = useState(null);
  useEffect(() => {
    setPortalElement(document.body);
  }, []);

  return (
    <Portal.Root container={portalElement} className="relative z-10">
      {Object.keys(stacks).map(position => {
        const [stack] = stacks[position];

        return (
          <div
            key={position}
            className={`fixed hidden flex-col-reverse lg:block ${TOAST_POSITION[position]}`}
          >
            {stack.map(name => (
              <ToastNotification key={name} isMobile={false} name={name} />
            ))}
          </div>
        );
      })}
      <div className="fixed bottom-0 left-1/2 block -translate-x-1/2 flex-col-reverse lg:hidden">
        {Object.keys(notifications).map(name => (
          <ToastNotification key={name} isMobile name={name} />
        ))}
      </div>
    </Portal.Root>
  );
}

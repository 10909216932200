import PropTypes from 'prop-types';
import {useEffect, useState, createContext, useMemo} from 'react';
import pushToDataLayers from '../lib/analytics/gtm/gtm-data-layer';
import {getAllFavorites, cleanFavorites} from '../lib/placeit/favorites';
import algoliaInsights from '../lib/algolia/insights';
import {setUserRequestedData} from '../lib/utils/userRequestedData';

let currentUserExport = null;
export const getCurrentUser = function getCurrentUser() {
  return currentUserExport;
};

export const setCurrentUserExportData = function setCurrentUserExportData(newUserData) {
  if (typeof newUserData !== 'object' || newUserData === null) {
    return;
  }
  if (currentUserExport === null) {
    currentUserExport = {};
  }
  currentUserExport = {...currentUserExport, ...newUserData};
};

export const UserContext = createContext({
  currentUser: {
    loggedIn: false,
    accountType: 'Free Account',
    plan: null, // monthly || annual
  },
  setCurrentUser: () => null,
  loadUser: () => null,
  logoutUser: () => null,
  ssoModalOpener: () => null,
  setSsoModalOpener: () => null,
});

/*
Logged in user:
currentUser = {loggedIn: true ...}

Logged out user:
currentUser = {loggedIn: false ...}

Loading, state will change soon:
currentUser = {loggedIn: 'loading' ...}
*/

export default function UserProvider({children}) {
  const [currentUser, setCurrentUserState] = useState(null);
  const [ssoModalOpener, setSsoModalOpener] = useState(null);
  const [isSsoOpen, setIsSsoOpen] = useState(false);

  const setCurrentUser = function setCurrentUser(user) {
    currentUserExport = user;
    setCurrentUserState(user);
  };

  const loadUser = async () => {
    setCurrentUser({loggedIn: 'loading'});

    // URLs with basic auth will break relative fetch calls, so we do this...
    const url = `${window.location.origin}/api/v1/get_user_type_banner/`;
    const response = await fetch(url, {
      credentials: 'include',
    });
    const result = await response.json();

    // defaults
    result.accountType = 'Free Account';
    result.plan = null;

    if (result.user_type.name === 'Visitor') {
      result.loggedIn = false;
    } else if (result.user_type.name.includes('Free Accounts')) {
      result.loggedIn = true;
    } else if (result.user_type.name.includes('Subscriber')) {
      result.loggedIn = true;
      result.accountType = 'Unlimited';
      result.plan = result.user_type.name === 'Annual Subscriber' ? 'annual' : 'monthly';
    }
    setCurrentUser(result);
    pushToDataLayers({envato_id: result.envato_id});

    if (result.loggedIn === true) {
      getAllFavorites(result.user_id);
      algoliaInsights.userToken = result.user_id;
    }

    setUserRequestedData(result);
  };

  const logoutUser = async () => {
    setCurrentUser({loggedIn: 'loading'});
    const logoutResponse = await fetch('/logout/', {
      credentials: 'include',
    });
    const logoutData = await logoutResponse.json();
    setCurrentUser({loggedIn: false});
    cleanFavorites();
    return logoutData.requires_sso_logout;
  };

  useEffect(() => currentUser || loadUser(), [currentUser]);

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      loadUser,
      logoutUser,
      ssoModalOpener,
      setSsoModalOpener,
      isSsoOpen,
      setIsSsoOpen,
    }),
    [currentUser, loadUser, logoutUser, ssoModalOpener, setSsoModalOpener, isSsoOpen]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

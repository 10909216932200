import {saveCouponCookie, deleteCouponCookie} from './coupons';
import formatPrices from './utils/formatPrices';

const PRICING_URL = '/api/v2/get_pricing_plans';

const getPricingUrl = function getPricingUrl(couponCode) {
  let url = PRICING_URL;
  if (couponCode) {
    url += `?coupon_code=${couponCode}`;
  }

  return url;
};

const getPrices = async function getPrices(couponCode) {
  const pricingUrl = getPricingUrl(couponCode);
  let prices = null;

  try {
    const response = await fetch(pricingUrl, {credentials: 'include'});

    if (!/application\/json/.test(response?.headers?.get('content-type'))) {
      console.error('Pricing not available');
    }

    const result = await response.json();
    prices = formatPrices(result);
  } catch (error) {
    console.error(error);
  }

  return prices;
};

const pricingDataProcessing = async function pricingDataProcessing(coupon) {
  const prices = await getPrices(coupon.code);

  if (prices?.coupon_valid === true) {
    saveCouponCookie(coupon.code);
  } else {
    deleteCouponCookie();
  }

  return prices;
};

export default pricingDataProcessing;

const REQUEST_DATA = {
  headers: {},
  referrerPolicy: 'strict-origin-when-cross-origin',
  mode: 'cors',
  credentials: 'include',
  accept: 'application/json, text/javascript, */*; q=0.01',
};

const GET_ENDPOINT = '/api/v2/bookmarked_stages_from_user?user_id=';
const POST_ENDPOINT = '/bookmarks/';

/**
 * Performs a request to an endpoint to get all favorites of an user.
 * @param {Number} userId  - The ID of the user that will be used
 *
 * @returns {Array<Number>} User favorites list
 */
export async function getAllFavoritesRequest(userId) {
  try {
    const favorites = await fetch(`${GET_ENDPOINT}${userId}`, {
      ...REQUEST_DATA,
      method: 'GET',
    }).then(response => response.json());

    if ('bookmarkedStages' in favorites) return favorites.bookmarkedStages;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Performs a request to an endpoint to save a stage as favorite
 * for the current user logged
 * @param {Number} stageId  - The ID of the stage that will be saved
 *
 * @returns {{
 *   action: String;
 *   stage: String;
 *   tags: Array<String>;
 *   userId: Number;
 * }} Stage saved data
 */
export async function createFavoriteRequest(id) {
  try {
    const favorite = await fetch(`${POST_ENDPOINT}${id}`, {
      ...REQUEST_DATA,
      method: 'POST',
    }).then(response => response.json());
    return favorite;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Performs a request to an endpoint to delete a stage from the favorites
 * for the current user logged
 * @param {Number} stageId  - The ID of the stage that will be saved
 *
 * @returns {{
 *   action: String;
 *   stage: String;
 *   userId: Number;
 * }} Stage deleted data
 */
export async function deleteFavoriteRequest(id) {
  try {
    const favorite = await fetch(`${POST_ENDPOINT}${id}`, {
      ...REQUEST_DATA,
      method: 'DELETE',
    }).then(response => response.json());
    return favorite;
  } catch (error) {
    throw new Error(error);
  }
}

import RegionMap from '../../var/region-map.json';

const getRegionCode = function getRegionCode(country) {
  const regionId = RegionMap[country.toUpperCase()];
  if (!regionId) {
    return null;
  }

  return regionId;
};

const getRegions = function getRegions() {
  return Array.from(new Set(Object.values(RegionMap)));
};

const parseRegionVariant = function parseRegionVariant(regionId) {
  if (!regionId) {
    return null;
  }

  return regionId.replace('_', '');
};

export {getRegionCode, getRegions, parseRegionVariant};

import {useContext} from 'react';
import dynamic from 'next/dynamic';
import {PriceContext} from '../../context/PriceContext';

export default function CouponLoader() {
  const couponContext = useContext(PriceContext);

  if (!couponContext?.couponStatus) {
    return null;
  }

  const CouponToast = dynamic(() => import('./CouponToast'));

  return (
    <div className="fixed top-0 z-50 mx-0 my-10 w-full">
      <CouponToast status={couponContext?.couponStatus} />
    </div>
  );
}

import PropTypes from 'prop-types';
import {Provider, Viewport} from '@radix-ui/react-toast';
import {createContext, useCallback, useMemo, useState} from 'react';
import useNavbarHeight from '../../hooks/useNavbarHeight';

import NotificationFactory from './NotificationsFactory';

export const NotificationsContext = createContext({});

export default function NotificationsProvider({children}) {
  const [notifications, setNotifications] = useState([]);
  const navbarHeight = useNavbarHeight();

  const notificate = useCallback(
    fn => options => {
      setNotifications(notifs => [...notifs, fn(options)]);
    },
    []
  );

  const remove = key => {
    setNotifications(notifs => {
      return notifs.filter(element => element.key !== key);
    });
  };

  const contextValue = useMemo(
    () => ({
      success: notificate(NotificationFactory.success),
      danger: notificate(NotificationFactory.danger),
      warning: notificate(NotificationFactory.warning),
      info: notificate(NotificationFactory.info),
      remove,
    }),
    []
  );

  return (
    <Provider>
      <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>
      <Viewport
        style={{top: `${navbarHeight}px`}}
        className="fixed bottom-0 right-0 z-20 flex min-h-min w-full max-w-full flex-col items-center gap-2 p-4 sm:w-auto sm:items-start"
      />
      {notifications}
    </Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

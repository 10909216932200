// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const TestExpAbCd3Fg1Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function useTestExpAbCd3Fg1() {
  return React.useContext(TestExpAbCd3Fg1Context);
}

export default TestExpAbCd3Fg1Context;
/* prettier-ignore-end */

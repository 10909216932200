import {deleteCookie, getCookie, setSessionCookie} from './utils/cookies';

const COUPON_PROPERTY = 'pl_coupon';

const getCookieCoupon = function getCookieCoupon() {
  if (typeof window === 'undefined') return null; // No SSR

  const couponCookie = getCookie(COUPON_PROPERTY);

  return couponCookie;
};

const getQueryCoupon = function getQueryCoupon(query) {
  let {pl_coupon: newCoupon = ''} = query;

  // sanitize incoming coupon code
  newCoupon && (newCoupon = newCoupon.replace(/[^a-zA-Z0-9!.?#%@$_-]/, ''));

  return newCoupon;
};

const getCoupon = function getCoupon(query) {
  const queryCoupon = getQueryCoupon(query);
  const cookieCoupon = getCookieCoupon();

  if (queryCoupon) {
    return {
      code: queryCoupon,
      source: 'query',
    };
  }

  if (cookieCoupon) {
    return {
      code: cookieCoupon.coupon_code,
      source: 'cookie',
    };
  }
  return {};
};

const deleteCouponCookie = function deleteCouponCookie() {
  deleteCookie(COUPON_PROPERTY);
};

const saveCouponCookie = async function saveCouponCookie(couponCode) {
  // since api is not properly saving coupon for rails app - lets hack it, for now.
  const url = `${window.location.origin}/coupons/${couponCode}/sign/`;
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
  });
  const json = await res.json();
  const encodedCoupon = encodeURIComponent(JSON.stringify(json.coupon));
  setSessionCookie(COUPON_PROPERTY, encodedCoupon);
};

export {getCoupon, saveCouponCookie, deleteCouponCookie};

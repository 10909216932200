// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const CountryContext = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function useCountry() {
  return React.useContext(CountryContext);
}

export default CountryContext;
/* prettier-ignore-end */

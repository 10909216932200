/*
  IMPORTANT NOTE:
  This file is modified by AST at build time.
*/
import React, {createContext, memo} from 'react';
import PropTypes from 'prop-types';
import CtaLevel from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__CtaLevel';
import TestExpAbCd3Fg1 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__TestExpAbCd3Fg1';
import _010119700000123129992359 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___010119700000123129992359';
import Region from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant__Region';
import Country from '../plasmic_components/plasmic/placeit_landing_pages/PlasmicGlobalVariant__Country';
import _102920241500110120240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___102920241500110120240500';
import _112720241500120420240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___112720241500120420240500';
import _112720241500120220240559 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___112720241500120220240559';
import _120220240601120420240500 from '../plasmic_components/plasmic/placeit/PlasmicGlobalVariant___120220240601120420240500';
// insert imports

// in addition to the variant contexts needed by plasmic... we can use this
// single variant context to hold all experiments... easier to use in our code.
export const VariantContext = createContext({
  variations: {},
  setVariations: async () => null,
});
const PlasmicVariants = memo(
  class extends React.Component {
    render() {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('PlasmicVariants Render', Date.now());
      return (
        <VariantContext.Provider value={this.props}>
          <_120220240601120420240500.Provider value={this.props._120220240601120420240500}>
            <_112720241500120220240559.Provider value={this.props._112720241500120220240559}>
              <_112720241500120420240500.Provider value={this.props._112720241500120420240500}>
                <_102920241500110120240500.Provider value={this.props._102920241500110120240500}>
                  <Country.Provider value={this.props.country}>
                    <Region.Provider value={this.props.region}>
                      <_010119700000123129992359.Provider
                        value={this.props._010119700000123129992359}
                      >
                        <TestExpAbCd3Fg1.Provider value={this.props.testexpabcd3fg1}>
                          <CtaLevel.Provider value={this.props.ctalevel}>
                            {this.props.children}
                          </CtaLevel.Provider>
                        </TestExpAbCd3Fg1.Provider>
                      </_010119700000123129992359.Provider>
                    </Region.Provider>
                  </Country.Provider>
                </_102920241500110120240500.Provider>
              </_112720241500120420240500.Provider>
            </_112720241500120220240559.Provider>
          </_120220240601120420240500.Provider>
        </VariantContext.Provider>
      );
    }
  }
);
PlasmicVariants.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PlasmicVariants;
export const ContextNames = [
  'ctalevel',
  'testexpabcd3fg1',
  '_010119700000123129992359',
  'region',
  'country',
  '_102920241500110120240500',
  '_112720241500120420240500',
  '_112720241500120220240559',
  '_120220240601120420240500',
];

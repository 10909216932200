const InitialValues = {
  experiment_id: '',
  cookie_preferences: {
    preferences: true,
    statistics: true,
    marketing: true,
  },
  pl_coupon: {
    applies_for_months: null,
    applies_to_all_items: false,
    applies_to_all_plans: false,
    applies_to_non_plan_charges: false,
    coupon_code: '',
    coupon_type: '',
    created_at: '',
    deleted_at: null,
    description: '',
    discount_percent: 15,
    discount_type: '',
    duration: '',
    id: 0,
    invoice_description: '',
    item_codes: [],
    max_redemptions: null,
    max_redemptions_per_account: 0,
    name: '',
    plan_codes: [''],
    redeem_by_date: null,
    redemption_resource: '',
    scope: '',
    single_use: true,
    state: '',
    temporal_amount: null,
    temporal_unit: null,
    updated_at: '',
  },
};

export default InitialValues;

/* eslint-disable camelcase */
function getCookie(cookieName) {
  if (typeof window === 'undefined') {
    // SSR has no cookies
    return cookieName ? '' : {};
  }

  const cookiesArr = document.cookie.split('; ').map(cookie => cookie.split('='));
  const cookiesObj = Object.fromEntries(cookiesArr);
  const {cookie_preferences, pl_coupon} = cookiesObj;

  // if cookie_preferences and pl_coupon is defined then decode it, else leave it as empty.
  const cookiesObjParsed = {
    ...cookiesObj,
    cookie_preferences: cookie_preferences
      ? JSON.parse(decodeURIComponent(cookie_preferences))
      : undefined,
    pl_coupon: pl_coupon ? JSON.parse(decodeURIComponent(pl_coupon)) : '',
  };

  if (cookieName) {
    return cookiesObjParsed[cookieName];
  }

  return cookiesObjParsed;
}

const setSessionCookie = function setSessionCookie(name, value) {
  if (name === 'cookie_preferences') {
    value = encodeURIComponent(JSON.stringify(value));
  }
  document.cookie = `${name}=${value};path=/`;
};

const deleteCookie = function deleteCookie(name) {
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
};

// This method was copied from Rails' codebase
const getAllExperimentsCookiesValues = function getAllExperimentsCookiesValues() {
  return document.cookie
    .split(';')
    .filter(c => c.trim().indexOf('_pl_gtmc') === 0)
    .map(c => c.trim().split('=')[1])
    .sort();
};

export {getCookie, setSessionCookie, getAllExperimentsCookiesValues, deleteCookie};

import {useEffect, useState, memo} from 'react';
import {enrichTrackEvents, enrichPageViewEvents} from '../enrich';
import GtmTag from './gtm-tag';
import pushToDataLayers from './gtm-data-layer';
import {getUserRequestedData} from '../../utils/userRequestedData';

const GtmTag1 = memo(function gtmTag1() {
  return <GtmTag id={process.env.NEXT_PUBLIC_GTM_TEST_ID} key="gtm1" dataLayerName="dataLayer" />;
});
const GtmTag2 = memo(function gtmTag2() {
  return <GtmTag id={process.env.NEXT_PUBLIC_GTM_ID} key="gtm2" dataLayerName="dataLayer" />;
});

const replaceUserId = properties => {
  if (Object.hasOwn(properties, 'userId')) {
    properties.placeit_user_id = properties.userId;
    delete properties.userId;
  }
  return properties;
};

const addEnvatoUUID = async properties => {
  const userData = await getUserRequestedData();

  if (userData && userData.envato_uuid) {
    properties.user_id = userData.envato_uuid;
  }

  return properties;
};

export default function GtmManager() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoading('done');
      }, 3000);
    }
  }, []);
  return (
    <>
      <GtmTag1 />
      {loading === 'done' && <GtmTag2 />}
    </>
  );
}

export function track(eventName, properties) {
  enrichTrackEvents(properties);
  pushToDataLayers({
    event: eventName,
    ...properties,
  });
}

export async function page() {
  let pageEnrichment = await enrichPageViewEvents();

  pageEnrichment = await addEnvatoUUID(pageEnrichment);
  pageEnrichment = replaceUserId(pageEnrichment);

  pushToDataLayers({
    event: 'Loaded a Page',
    ...pageEnrichment,
  });
}

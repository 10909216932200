// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _010119700000123129992359Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_010119700000123129992359() {
  return React.useContext(_010119700000123129992359Context);
}

export default _010119700000123129992359Context;
/* prettier-ignore-end */

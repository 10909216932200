import {getCookie, getAllExperimentsCookiesValues} from '../utils/cookies';
import {getCurrentUser} from '../../context/UserContext';
import getNetworkData from '../utils/getNetworkData';
import getDeviceCategory from '../utils/getDeviceCategory';
import {getGoogleSessionId, getGoogleClientId} from '../utils/googleData';

/**
 * This function is used to enrich the events with common properties.
 * Applicable to all `pageview` and `track` events.
 *
 * @return {Object} - The object with the common properties
 */
function commonEnrichment() {
  const currentUser = getCurrentUser();

  return {
    experiment_id: getCookie('experiment_id'),
    title: document.title,
    platform: 'commercial',

    // page props (Segment used to add them automatically)
    site_id: currentUser?.user_id || 0,
    page_location: window?.location.href,
    page_path: window?.location.pathname,
    page_domain: window?.location.hostname,
    page_type: window?.customPageMetadata?.pageType || window?.pageMetadata?.pageType,
    page_category: window?.customPageMetadata?.pageCategory || window?.pageMetadata?.pageCategory,
    referrer: document.referrer,
    search: window?.location.search,
    page_title: document.title,
    page_url: window?.location.href.split('?')[0],
    segment: getCookie('userSegment'),

    // User related
    userId: currentUser?.user_id || 0,
    user_type_name: currentUser?.user_type?.name,
    hasSubscription: currentUser?.user_subscription,
    subscription_type: currentUser?.subscription_type,
    unsubscribe_code: currentUser?.unsubscribe_code,
    device_category: getDeviceCategory(window?.navigator?.userAgent.toLowerCase()),

    properties: {
      email: currentUser?.email || null,
      status: currentUser?.status || null,
      plan_name: currentUser?.subscription_name || null,
      plan_type: currentUser?.subscription_type || null,
    },
  };
}

export async function enrichPageViewEvents(obj = {}) {
  Object.assign(obj, {
    page_title: document.title,
    page_location: window?.location.href,
    page_path: window?.location.pathname,
    page_domain: window?.location.hostname,
    page_url: window?.location.href.split('?')[0],
    stage_id: window?.pdpStageId,

    ...commonEnrichment(),
  });

  const googleClientId = await getGoogleClientId();
  const googleSessionId = await getGoogleSessionId();

  if (googleClientId) {
    obj.google_client_id = googleClientId;
  }

  if (googleSessionId) {
    obj.session_id = googleSessionId;
  }
  return obj;
}

export function enrichTrackEvents(obj) {
  const networkData = getNetworkData();

  Object.assign(obj, {
    userAgent: window?.navigator?.userAgent,
    downlink: networkData.downlink,
    networkEffectiveType: networkData.effectiveType,
    rtt: networkData.rtt,
    saveNetworkData: networkData.saveNetworkData,
    deviceMemory: window?.navigator?.deviceMemory,

    ...commonEnrichment(),
  });
  return obj;
}

export function enrichIdentifyEvents(obj) {
  const currentUser = getCurrentUser();

  Object.assign(obj, {
    name: getCookie('pl_tracker_id_v2'),
    country: getCookie('gtmc_user_location'),
    experiment_name: getAllExperimentsCookiesValues().join('!'),
    email: currentUser?.email,
    hasSubscription: currentUser?.user_subscription,
    subscription_type: currentUser?.subscription_type,
    status: currentUser?.status,
    unsubscribe_code: currentUser?.unsubscribe_code,
    device_category: getDeviceCategory(window?.navigator?.userAgent.toLowerCase()),
  });
  return obj;
}

// at times we've had multiple GTM
const dataLayerNames = ['dataLayer'];

export default function pushToDataLayers(data) {
  dataLayerNames.forEach(dln => {
    window[dln] = window[dln] || [];
    window && window[dln].push(data);
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
      console.log('GTM data layer push (not shown in prod):', dln, data);
    }
  });
}

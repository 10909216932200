import {useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {unsubscribeMailingListsUser} from '../../lib/utils/unsubscribeMailingListsUser';
import {isMarketingCookieEnabled} from '../../lib/utils/cookiesPolicy';

export default function CookiebotManager() {
  const {currentUser} = useContext(UserContext);

  const consentAction = () => {
    if (!isMarketingCookieEnabled() && currentUser?.loggedIn) {
      unsubscribeMailingListsUser();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('CookiebotOnConsentReady', consentAction);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('CookiebotOnConsentReady', consentAction);
      }
    };
  }, [currentUser?.loggedIn]);

  return null;
}

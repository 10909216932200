/*
 * This is a generic GTM tag, unaware of its ID
 */

import Script from 'next/script';
import PropTypes from 'prop-types';

export default function GtmTag({id, dataLayerName}) {
  return (
    <>
      <Script
        id={`gtm-data-${id}`}
        dangerouslySetInnerHTML={{
          __html: `window['${dataLayerName}'] = window['${dataLayerName}'] || [];`,
        }}
      />
      <Script
        id={`gtm-tag-${id}`}
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','${dataLayerName}','${id}');
          `,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${id}}`}
          height="0"
          width="0"
          style={{display: 'none', visibility: 'hidden'}} // eslint-disable-line react/style-prop-object
          title="gtm-test noscript"
        />
      </noscript>
    </>
  );
}

GtmTag.defaultProps = {
  id: '',
  dataLayerName: 'dataLayer',
};

GtmTag.propTypes = {
  id: PropTypes.string,
  dataLayerName: PropTypes.string,
};

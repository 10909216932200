import {Consent, consented} from '@envato/cookie-consent';
import {getCurrentUser} from '../../context/UserContext';

const COOKIE_CATEGORY = {
  statistics: 'statistics',
  marketing: 'marketing',
  preferences: 'preferences',
};

const MARKETING_BLACKLISTED_EVENTS = [
  'email:mainmenu:clicked',
  'email:submenu:clicked',
  'email:mobilemenu:clicked',
  'email:ctablock:seen',
  'email:list:clicked',
  'email:footer:clicked',
  'email:dropdown:clicked',
  'email:email:reminder',
  'email:search:success',
  'email:Completed Order',
  'email:result.shown',
  'email:IndustrySubmitted',
  'email:open.faq',
  'email:smartTemplate:colorChange',
  'email:smartTemplate:customGraphicChange',
  'marketing:ctaBlockButton:clicked',
  'marketing:chat:initialized',
  'marketing:chat:replied',
  'marketing:landing:clicked',
  'marketing:email:draftSaved',
  'marketing:email:signup',
  'marketing:survey:shown',
  'marketing:email:monthlySubscription',
  'marketing:email:annualSubscription',
  'marketing:event',
  'marketing:uservoice:shown',
  'marketing:email:stageBookmarked',
  'marketing:email:stageSeen',
  'email:highintent',
  'email:subscription:canceled',
  'email:purchase:success',
  'email:subscription:success',
  'email:Download:click',
  'email:freeStageDownload',
  'email:StageModified',
  'email:user:login',
  'email:PricingModal:Opens',
  'email:PlanCTA:Clicked',
  'email:user:signup',
  'email:draft:Saved',
  'email:forcesignup',
  'email:purchase:canceled',
  'email:StageBookmarked',
  'email:AbandonedCart',
];

const BLACKLISTED_COOKIES = {
  statistics: [
    'pl_tracker_id',
    'pl_tracker_id_v2',
    'hj_logo_survey',
    'userSegment',
    'subscriptionType',
  ],
  marketing: [
    'iterableEmailCampaignId',
    'iterableTemplateId',
    'surveyNotification',
    'noSegment',
    'userStatus',
    'subscriptionType',
    'gtmc_pageViewCount',
    'gtmc_audience1',
    'gtmc_audience1HasRun',
    'gtmc_seenPricingModal',
    'gtmc_streamloots',
    'gtmc_userTiming_reachStage',
    'gtmc_userTiming_signedUp',
    'gtmc_csat_survey_2022_AO',
    'mobile_survey_app',
    'gtmc_filledFormAndCancelled',
    'gtmc_globalChat',
    'gtmc_seenPricingModalChat',
    'gtmc_purchaseCanceled',
    'gtmc_optinModalSeen',
    'gtmc_user_location',
    'gtmc_showSignup',
    'gtmc_textChanged',
    'gtmc_graphicChanged',
    'gtmc_graphicColorChanged',
    'gtmc_uploadedImage',
    'gtmc_showSignup',
    'gtmc_optimizeTestForceSignup',
    'gtmc_usabilityTest',
    'gtmc_session',
    'gtmc_lead',
    'gtmc_sessionTime',
    'gtmc_subButtonAnimation',
    'gtmc_seenPricingModal',
    'gtmc_usabilityTest',
    'gtmc_screenSharing',
    'gtmc_noSearchResults',
    'gtmc_highIntent',
    'gtmc_cybermonday_2020',
    'gtmc_printful',
    'gtmc_restream',
    'gtmc_spreadshop',
  ],
  preferences: [],
};

const getCookiePolicySetting = name => {
  // This value returns false if the consent is not defined.
  return consented(Consent[name]);
};

const isStatisticsCookieEnabled = () => getCookiePolicySetting(COOKIE_CATEGORY.statistics);

const isMarketingCookieEnabled = () => getCookiePolicySetting(COOKIE_CATEGORY.marketing);

const isPreferencesCookieEnabled = () => getCookiePolicySetting(COOKIE_CATEGORY.preferences);

const anonymizeUserData = data => {
  const currentUser = getCurrentUser();
  const properties = {...data};
  const anonymizeUser = isStatisticsCookieEnabled() === false;

  if (anonymizeUser && properties) {
    properties.email = currentUser?.email;
    properties.user_id = null;
    properties.userId = null;
  }
  if (anonymizeUser && properties && properties.user_properties) {
    properties.user_properties.email = currentUser?.email;
    properties.user_properties.userId = null;
    properties.user_properties.user_id = null;
  }
  if (anonymizeUser && properties && properties.event_properties) {
    properties.event_properties.email = currentUser?.email;
    properties.event_properties.properties.email = currentUser?.email;
    properties.event_properties.userId = null;
    properties.event_properties.user_id = null;
  }
  return properties;
};

const isBlacklistedEvent = eventName => MARKETING_BLACKLISTED_EVENTS.includes(eventName);

const isBlacklistedCookie = cookieName => {
  const {statistics, marketing, preferences} = BLACKLISTED_COOKIES;
  const cookiesList = [];

  if (isStatisticsCookieEnabled() === false) {
    cookiesList.push(...statistics);
  }

  if (isMarketingCookieEnabled() === false) {
    cookiesList.push(...marketing);
  }

  if (isPreferencesCookieEnabled() === false) {
    cookiesList.push(...preferences);
  }

  return cookiesList.includes(cookieName);
};

export {
  isStatisticsCookieEnabled,
  isMarketingCookieEnabled,
  isPreferencesCookieEnabled,
  isBlacklistedEvent,
  isBlacklistedCookie,
  anonymizeUserData,
};
